@font-face {
    font-family: "Inter";
    src: url('../../public/fonts/Inter-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter Black";
    src: url('../../public/fonts/Inter-Black.ttf') format('ttf');
    font-weight: 900;
    font-style: bold;
}

@font-face {
    font-family: "Inter Bold";
    src: url('../../public/fonts/Inter-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: bold;
}